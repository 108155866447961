import React from "react"
import Layout from "@components/layout"
import Meta from "@components/meta"
import * as Heading from "@components/heading"
import * as Quote from "../components/quote"
import * as Button from "@components/button"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Chevron from "@svgs/chevron.svg";

const RecruitH3 = ( {no, text} ) => (
  <h3 className="inline-flex items-center text-2xl my-7 border bg-secondary h-10 lg:bg-white lg:border-0">
    <div className="m-5">{no}</div>
    <div className="m-3 mr-5">{text}</div>
  </h3>
);


const Recruit = () => (
  <Layout>
    <Meta
      title="採用情報"
      desc="産業用電気機器の専門商社として成長を続ける当社の魅力を紹介します。『マイナビ』 『リクナビ』サイトに当社の情報を掲載しております。"
    />{ }
    <Heading.H1 text="採用情報" />
    <div className="p-3 absolute right-44 top-44 lg:hidden">
      <Button.HandButton></Button.HandButton>
    </div>
    <div className=" lg:max-w-4xl lg:mx-auto">
      <div className="px-5 ">
        <Heading.H3 text="木原興業の魅力" id="policy" />
        <p>産業用電気機器の専門商社として成長を続ける当社の魅力を紹介します。</p>
        <Quote.Blockquote text="※産業用電気機器とは 一般家庭で使う家電製品ではなく、発電施設や工場など大きな電気を必要とする場所で使用される設備です。当社は様々な業種のお客様と長年取引しており、産業や社会に欠かせない電気設備システムに関するインフラ整備を担っています。" />
      </div>

      <RecruitH3 no="1" text="多くの取引が「人」で選ばれています" />
      <div className="px-5 lg:flex">
        <div>
          <p className="my-4 lg:mt-0 lg:pr-10">人に好かれる人たちが集まっている当社は、和気あいあいとした雰囲気で、一緒に仕事をしていて心地良さを感じる人たちばかりです。</p>
          <p className="my-4 lg:mt-0 lg:pr-10">お客様からも同じように感じていただいており、「人」によって数百社もの取引先から選ばれ続けているのが当社の特長です。</p>
          <p className="my-4 lg:mt-0 lg:pr-10">長年にわたって当社の「人」に価値を感じていただき、安心・信頼を得ており、社員一人ひとりの魅力を武器に、さらなる成長を目指しています。</p>
        </div>
        <StaticImage src="../images/recruit_1.jpg" className="mb-12 lg:w-96 lg:flex-shrink-0" />
      </div>

      <RecruitH3 no="2" text="いずれも電気機器の提案、三者三様の営業スタイル" />
      <div className="px-5 lg:flex lg:flex-row-reverse">
        <div>
          <p className="my-4 lg:mt-0 lg:pl-10">電気機器の営業といっても取り扱い商品は様々で、お客様により提案する商品が違います。そこで当社では、営業部門を「造船メーカー」・「鉄鋼／プラントメーカー」「盤／セットメーカー」の3部門に分けています。</p>
          <p className="my-4 lg:mt-0 lg:pl-10">部門によって、商談の仕方や営業スタイルが三者三様です。プラントは何十年単位で設備を導入するため、長年にわたって関係性を築く必要があります。盤・セットメーカーにはスピード重視で、いくつものお客様先に細かく対応する必要があります。</p>
          <p className="my-4 lg:mt-0 lg:pl-10">価値ある提案を生み出すなどそれぞれに面白みがあり、人との関わりを通じた貴重な経験ができます。</p>
        </div>
        <StaticImage src="../images/recruit_2.jpg" className="mb-12 lg:w-96 lg:flex-shrink-0" />
      </div>

      <RecruitH3 no="3" text="キレイなオフィスと万全のバックアップ体制" />
      <div className="px-5 lg:flex">
        <div>
          <p className="my-4 lg:mt-0 lg:pr-10">当社の自慢の一つが、キレイでスタイリッシュなオフィス。創業110年以上の老舗企業という言葉のイメージとはまた違った、近代的で開放感のある空間となっています。</p>
          <p className="my-4 lg:mt-0 lg:pr-10">仕事のバックアップ体制も充実しています。たとえば文系出身者でも、社内外での研修や勉強会、講習といった様々な制度によって、専門知識をイチから学んでいけます。</p>
          <p className="my-4 lg:mt-0 lg:pr-10">新卒入社した先輩たちも、半分以上が文系出身ですが、着実に知識をつけて活躍しています。</p>
        </div>
        <StaticImage src="../images/office_1-2.jpg" className="mb-12 lg:w-96 lg:flex-shrink-0" />
      </div>

      <div className="px-5 mb-12">
        <Heading.H3 text="募集要項・応募方法" />
        <p className="my-4">『マイナビ』 サイトに当社の情報を掲載しております。下記ナビサイトのバナーより当社のページへアクセスが出来ます。</p>
        <Heading.H4 text="2025年3月新卒者対象" />
        <a href="https://job.mynavi.jp/25/pc/search/corp256558/outline.html" target="_blank" rel="noopener noreferrer" className="flex items-center justify-between w-full h-full px-4">
        <StaticImage src="../images/mynavi2025.jpg" className="mb-4 w-64" />
        </a>
        
        <Heading.H4 text="中途採用" />
            <p className="mb-4 ml-4">個別にご連絡ください</p>
        <Heading.H3 text="求人に関する問い合わせ" />
        <Link to="/contact" className="flex items-center">
            <Chevron className="w-3 h-3 text-primary" />
            <span className="text-primary">お問い合わせ</span>
        </Link>
      </div>
    </div>
  </Layout>
)

export default Recruit;